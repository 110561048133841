import React, { useMemo } from "react";
import { Stack, Title, Anchor, Flex } from "@mantine/core";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { AREAS, AREA_ROUTES } from "@/constants/area";

export default function AreaRouteFooter(): JSX.Element {
  const { t, i18n, ready } = useTranslation();
  const prefectures = Object.values(
    t("locations:prefectures", { returnObjects: true }) as any
  );
  const cities: any = useMemo(() => {
    if (ready)
      return prefectures.reduce((item: any, prefecture: any) => {
        Object.keys(prefecture).forEach((key) => {
          item[key] = prefecture[key];
        });
        return item;
      }, {});
  }, [prefectures, ready]);

  return (
    <Flex direction={{ base: "column", md: "row" }} gap="lg">
      <Stack w={{ base: "100%", md: "40%" }} className="gap-1">
        <Title order={4} fw={700} c="dark.9" mb="xs">
          {t("area:common.links.area")}
        </Title>
        {AREAS?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Anchor
                component={Link}
                locale={i18n.language}
                href={`/${item}-area`}
                c="dark.8"
                fz="sm"
              >
                {t("area:common.routes", { area: cities[item] })}
              </Anchor>
            </React.Fragment>
          );
        })}
      </Stack>
      <Stack gap="sm" w={{ base: "100%", md: "60%" }} className="flex-1">
        <Title order={4} fw={700} c="dark.9" mb="xs">
          {t("area:common.links.route")}
        </Title>
        <Flex direction={{ base: "column", md: "row" }} className="gap-1">
          <Stack gap="sm" w={{ base: "100%", md: "50%" }} className="gap-1">
            {AREA_ROUTES?.slice(0, 20)?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Anchor
                    component={Link}
                    locale={i18n.language}
                    href={`/${item[0]}-to-${item[1]}`}
                    c="dark.8"
                    fz="sm"
                  >
                    {t("area:common.footer_route_title", {
                      area1: cities[item[0]],
                      area2: cities[item[1]],
                    })}
                  </Anchor>
                </React.Fragment>
              );
            })}
          </Stack>
          <Stack gap="sm" w={{ base: "100%", md: "50%" }} className="gap-1">
            {AREA_ROUTES?.slice(20)?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Anchor
                    component={Link}
                    locale={i18n.language}
                    href={`/${item[0]}-to-${item[1]}`}
                    c="dark.8"
                    fz="sm"
                  >
                    {t("area:common.footer_route_title", {
                      area1: cities[item[0]],
                      area2: cities[item[1]],
                    })}
                  </Anchor>
                </React.Fragment>
              );
            })}
          </Stack>
        </Flex>
      </Stack>
    </Flex>
  );
}
